import React from 'react'

export default function Nav() {
  return (
    <nav>
      <div>
        <a href='#about-me'>About</a>
        <a href='#why-us'>Why Us</a>
        <a href='#services'>Services</a>
        <a href='#contact'>Contact</a>
      </div>
       
    </nav>
  )
}
