import React from 'react'
import './Services.css'
import Gallery from './Gallery'
import Prices from './Prices'

export default function Services() {
  return (
    <section id='services'>
        <h2 className='section-header'>Services</h2>

       
        <div className='service-list'>
          <b >At Mane Attraction, we understand that your pets are your family, and we are committed to providing them with the highest level of care while you are away.</b> <br/><br/>
          <b>We offer a wide range of services to meet your needs, including:</b>

        </div>
        
        <div className='service-list'>
          <ul>
            <li><b>Horse sitting: </b>We will provide daily care for your horse, including feeding, grooming, stable management, bringing in and turning out, poo picking, and rugs/fly hoods if required. *Rates are dependant on the length of the visit and the number of horses.</li>
            <li><b>House sitting: </b>We will stay at your home overnight to provide your pet with companionship and care. This include care for up to 2 horses and small domestic animals. Please contact me for prices on additional animals. </li>
            <li><b>Exercising: </b>We can also provide exercise for your horse, at an additional cost.</li>
            <li><b>Other pets: </b>We can also look after other pets, such as poultry, at an additional cost.</li>
          </ul>
        </div>

        <Prices />
     
        <div className='service-list'>
        <i>Additional mileage will be charged at 0.50 pence per mile over 10 miles from Cheddleton ST137DQ.</i>

          <p>
          We are passionate about providing our clients with peace of mind. We will work with you to create a customised care plan that meets the specific needs of your pet. We understand that every pet is different, and we will tailor our services to ensure that your pet is happy and healthy while you are away.
          </p>

          
        </div>

        <Gallery />
       

    </section>
  )
}
