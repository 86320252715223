import React from 'react'
import horses from '../assets/horses.mp4'
import logo from '../assets/logo.png'

export default function Hero() {
  return (
    <div id='hero'>
      
       <video id='horse-vid' autoPlay muted loop playsInline controls={false}>
                <source src={horses} type="video/mp4"/>
                Your browser does not support the video tag.
                
        </video>
        <div id='gradient'>
          <img id='hero-logo' src={logo}/>
        </div>

    </div>
  )
}
