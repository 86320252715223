import React from 'react'
import './Prices.css'

export default function Prices() {
  return (
    <div  id='prices'>
      <h3>Pricing</h3>
      <table>
        <thead>
          <tr>
            <th></th>
            <th style={{textAlign:'right'}}>Cost</th>
          </tr>
        </thead>

        <tbody>
  
          <tr>
            <td>Horse Sitting</td>
            <td style={{textAlign:'right'}}>£15-£25 Per hour*</td>
          </tr>
         
          <tr>
            <td>Horse Exercise</td>
            <td style={{textAlign:'right'}}>£15 Per hour</td>
          </tr>
      
        </tbody>
        
       
     
       
     
      
     </table>
{/*
      <table>
        <tr>
          <th>Cats and small animals</th>
          <th style={{textAlign:'right'}}>Cost</th>
        </tr>
       
        <tr>
          <td style={{width: '70%', textAlign:'left'}}>Half-hour pet visits 
Feeding change of water, empty litter tray if required. Clean out hutch if required, play fuss and cuddles</td>
          <td style={{textAlign:'right', width: '30%'}}>£10</td>
        </tr>
     
     
      
  </table>*/}






 
       
    </div>
  )
}
