import './App.css';
import Hero from './components/Hero';
import Nav from './components/Nav';
import AboutMe from './components/AboutMe';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WhyUs from './components/WhyUs';

function App() {
  return (
    <div className="App">
      <ToastContainer position='top-center'/>
      <Nav/>
      <Hero />
      <AboutMe/>
      <WhyUs />
      <Services/>
      <Contact />
      <Footer/>
    </div>
  );
}

export default App;
