import React, { useRef, useState} from 'react'
import './Contact.css'
import { toast } from 'react-toastify'
import axios from 'axios'

export default function Contact() {

    const formRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();
    const numberRef = useRef();
    const msgRef = useRef();

    const [ loading, setLoading ] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    if(!nameRef.current.value || !numberRef.current.value || !emailRef.current.value || !msgRef.current.value ){
      toast.error("Please fill in all required fields")
    } else {
      setLoading(true)

      axios.post(`${process.env.REACT_APP_API_URL}/mane-attraction/contact`,
      {
        name: nameRef.current.value,
        email: emailRef.current.value,
        number: numberRef.current.value,
        msg: msgRef.current.value
      })
      .then(() => {
        toast.success("Sent Successfully")
        setLoading(false)
        formRef.current.reset()
      })
      .catch(() => {
        toast.error("Oops, something went wrong")
        setLoading(false)
      })
    }
    
  };

  return (
    <div id='contact' className='white'>
      <h1 className='section-header'>Contact</h1>
      <p style={{padding:'1rem'}}>
          We offer our services in a variety of locations, so please contact us today to learn more about how we can help you care for your furry family member.
          </p>
      <p style={{padding:'1rem'}}>All enquiries, please fill in the form below and we will get back to you as soon as possible.</p>

      <form ref={formRef} onSubmit={sendEmail}>
        <input placeholder='Name' ref={nameRef} name='Name'/>
        <input placeholder='Number' type='number' ref={numberRef} name='Number'/>
        <input placeholder='Email Address' ref={emailRef} name='Email'/>
     
        <textarea ref={msgRef} placeholder="Message" name='Msg'></textarea>
        <button id='btn' type='submit' disabled={loading}>{loading ? "Sending..." : "Submit"}</button>
      </form>
      <p id='consent'>By submitting this form, I consent to Mane Attraction storing the above information to contact me regarding any services offered by Mane Attraction.</p>
    </div>
  )
}