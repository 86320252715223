import React from 'react'
import './AboutMe.css'
import about from '../assets/about.jpg'
import tick from '../assets/tick.jpg'

export default function AboutMe() {
  return (
    <section id='about-me'>

      <img src={about} id='about-img'/>

       <div id='about-container'>
          <h2 style={{textAlign:'center'}} className='section-header'>About me</h2>
          <p>Hi, my name is Georgina and welcome to Mane Attraction Horse and pet services.<br/><br/>
          Growing up on a small holding, I worked with and cared for a wide range of animals. My first passion was horses when I started competing at the age of 13, and I was lucky that the first one I owned was a 16H Hanoverian X from Olympian, Michael Whitaker. I went on to work part-time as a groom whilst also studying for a GNVQ Level 3 in small animal care.<br/><br/>

          My father was a Police dog handler so I was always surrounded by large powerful dog breeds and often travelled with him to attend Police dog trials. After 15 years working for Staffordshire Police I decided to pursue my dream of working with animals.<br/><br/>

          I currently live in Cheddleton with my husband, sharing our home with Princess Theia a Siberian husky, our cat Rosie, 3 guinea pigs and 3 geese so there is never a dull moment!<br/><br/>
          I understand how worrying and stressful it can be for owners to leave their animals while they are on holiday but I pride myself in treating all my client’s pets with the same love, care, and attention as I do my own.
          </p>


          <div className='tick-container'>
            <img className='tick' src={tick}/>
            <h4>Fully insured and DBS checked</h4>
          </div>

          <div className='tick-container'>
            <img className='tick' src={tick}/>
            <h4>GNVQ level 3 in small animal care</h4>
          </div>

          <div className='tick-container'>
            <img className='tick' src={tick}/>
            <h4>Accredited Horse first aid</h4>
          </div>

        </div> 
    </section>
  )
}
