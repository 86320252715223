import React from 'react'
import './Gallery.css'
import one from '../images/1.jpg'
import two from '../images/2.jpg'
import three from '../images/3.jpg'
import four from '../images/4.jpg'
import five from '../images/5.jpg'
import six from '../images/6.jpg'
import seven from '../images/7.jpg'
import eight from '../images/8.jpg'
import nine from '../images/9.jpg'
import ten from '../images/10.jpg'
import eleven from '../images/11.jpg'

export default function Gallery() {
  return (
    <div  id='gallery'>
      <div id='gallery-container'>
      <img className='gallery-img' src={five} alt='5'/>
      <img className='gallery-img' src={six} alt='6'/>
        <img className='gallery-img' src={one} alt='1'/>
        <img className='gallery-img' src={ten} alt='9'/>
        <img className='gallery-img' src={three} alt='3'/>
        <img className='gallery-img' src={four} alt='4'/>
        <img className='gallery-img' src={seven} alt='7'/>
        <img className='gallery-img'  src={eight} alt='8'/>
        <img className='gallery-img' src={two} alt='2'/>
        <img className='gallery-img' src={eleven} alt='10'/>

      </div>
    </div>
  )
}
