import React from 'react'

export default function Footer() {
  return (
    <footer>
      <p>© {new Date().getFullYear()} Mane Attraction</p>

      <a style={{marginBottom: 10}} href='https://www.frantech.org.uk'>Website by Frantech</a>

    </footer>
  )
}
