import React from 'react'
import './WhyUs.css'

export default function WhyUs() {
  return (
    <div id='why-us'>
        <h3>Why Us?</h3>

        <p > 
            Are you looking for a caring and reliable pet sitter? Look no further! Our professional pet sitting services are designed to provide your furry friends with the utmost love, attention, and care while you're away. Here's why we're the perfect choice for your pet:
        </p>

        <ul>
            <li><b>Trustworthy and Experienced:</b> Our team of pet sitters is highly trained and experienced in handling a wide range of pets. We understand that every animal has unique needs and preferences, and we'll make sure to cater to them with the utmost care.</li>
            <li><b>Personalised Care:</b> We believe in treating every pet as an individual. During our initial consultation, we'll take the time to understand your pet's routine, dietary requirements, exercise needs, and any other specific instructions. This ensures that we provide a customized and tailored experience for your beloved companion.</li>
            <li><b>Safety First:</b> We prioritise the safety and well-being of your pets above everything else. Our pet sitters are trained in basic first aid and are familiar with emergency procedures. We'll also keep a close eye on your home, ensuring it remains secure while you're away.</li>

            <li><b>Regular Updates:</b> We understand how important it is for you to stay connected with your pet, even when you can't be there physically. That's why we provide regular updates, including photos and videos, so you can see how much fun your furry friend is having while you're apart.</li>

            <li><b>Additional Services:</b> Apart from pet sitting, we offer a range of additional services to make your life easier. This includes dog walking, grooming, administering medication, and even basic training reinforcement. We're here to ensure your pet's needs are met comprehensively.</li>
            <li><b>Peace of Mind:</b> Leaving your pet behind can be stressful, but with our pet sitting services, you can have peace of mind knowing that your furry friend is in capable and caring hands. We're fully licensed, bonded, and insured, so you can trust us to take care of your pet like they're our own.</li>
        </ul>

        <p>
            Choose us as your pet sitter, and we'll go above and beyond to make your absence stress-free for both you and your beloved pet. Contact us today to schedule a consultation and give your furry friend the care they deserve!
        </p>
    </div>
  )
}
